import React from "react"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"
import Header from "./../components/common/Header"
import { graphql, useStaticQuery } from "gatsby"
import Card from "./../components/common/Card"
import Reviews from "./../components/Reviews"

export default function Page() {
  const data = useStaticQuery(graphql`
    query Versicherungen {
      header: file(
        relativePath: { eq: "headers/dierda_versicherungen_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bu: file(
        relativePath: {
          eq: "headers/dierda_berufsunfaehigkeitsversicherung_header.jpg"
        }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alter: file(
        relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Versicherungen"
        description="Eine Erkrankung oder ein Unfall kann für jeden einzelnen schnell zu
          einem Leben am Rande des Existenzminimums führen. Wir finden für Dich
          die passende Versicherung, die greift, sobald es darauf ankommt."
        path="/versicherungen/"
      />
      <Header
        link="#angebote"
        linktext="Zum Angebot"
        image={data.header.childImageSharp.fluid}
      >
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  ">
          <span className="text-blue-500">Versicherungen</span>
        </h1>

        <p className="prose prose-lg text-gray-700 ">
          Eine Erkrankung oder ein Unfall kann für jeden einzelnen schnell zu
          einem Leben am Rande des Existenzminimums führen. Wir finden für Dich
          die passende Versicherung, die greift, sobald es darauf ankommt.
        </p>
      </Header>
      <div className="container mx-auto my-6">
        <div className="relative  pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center" id="angebote">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Unsere Versicherungsangebote
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-700 sm:mt-4">
                Nachfolgend findest Du eine Auswahl unserer
                Versicherungs-Leistungen.
              </p>
            </div>
            <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              <Card
                image={data.bu.childImageSharp.fluid}
                title="Berufs­unfähigkeits­versicherung"
                link="/versicherungen/berufsunfaehigkeitsversicherung/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Die Dienst- und Berufs­unfähigkeits­versicherung als individueller Schutz für jeden Erwerbstätigen."
              />

              <Card
                image={data.alter.childImageSharp.fluid}
                title="Altersvorsorge"
                link="/versicherungen/altersvorsorge/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Deine sichere Altersvorsorge: Genieße den Ruhestand in der Sonne."
              />
            </div>
          </div>
        </div>
        <Reviews />
      </div>
    </Layout>
  )
}
